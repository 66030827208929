*:focus {
  outline: none;
}

.front-landing-from .input-with-icon {
  width: 250px;
  height: 50px;
  margin-right: 32px;
  margin-bottom: 0px;
}

.front-landing-from .input-with-icon.input-with-icon img {
  top: 18px;
}

.front-overal-paper {
  width: 100%;
  background-color: white;
  padding: 40px 57px 40px 57px;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.3);
}
.document strong {
  color: #000;
}
.document ul li ul {
  margin-left: 20px;
}
.last-modified-header {
  margin-bottom: 36px;
}

.last-modified-header img {
  width: 26px;
  height: 26px;
}

.last-modified-header small {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #737781 !important;
  margin-left: 5px;
  margin-top: 2px;
}

.front-overal-paper h2 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
  text-align: left;
  height: unset !important;
  margin-bottom: 20px;
}

.front-overal-paper h6 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  font-style: normal;
  line-height: 1.63;
  margin-bottom: 0px;
  letter-spacing: normal;
  color: #737781bd;
}

.front-overal-paper h3 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  font-style: normal;
  line-height: 1.63;
  margin-bottom: 0px;
  letter-spacing: normal;
  color: #686c75;
}

.front-overal-paper p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #737781bd;
  margin-bottom: 30px;
}

.front-sub-page-items {
  padding-top: 25px;
  margin-bottom: 30px;
}

.front-sub-page-items a {
  color: #b3c0ce;
  border: none;
  outline: none;
  background-color: transparent;
  margin-right: 40px;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  padding-bottom: 10px;
  transition: 0.7s color, 0.7s border-bottom-color;
}
.dropDownInput {
  position: absolute;
  background: red;
  width: 100%;
  left: 0;
  top: 0;
  display: block;
}
.tagsView {
  overflow-y: visible !important;
  max-height: 350px;
}
.langFlag {
  position: relative !important;
  left: auto !important;
  top: auto !important;
  width: 17px;
  margin-right: 5px;
}
.front-sub-page-items a.active {
  padding-bottom: 8px;
  color: white;
  border-bottom: 2px solid white;
}

.front-sub-page-items a:hover {
  padding-bottom: 8px;
  color: white;
  border-bottom: 2px solid white;
}

.front-overal-paper-contact {
  width: 100%;
  background-color: white;
  padding: 40px 65px 40px 65px;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.3);
  margin-bottom: 110px;
}

.front-overal-paper-contact h3 {
  color: #44566c;
  font-family: Roboto;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 34px;
}

.front-overal-paper-contact .content .help-links {
  width: 300px;
  padding: 15px 0px 60px 40px;
  border-left: 2px solid #73778182;
  max-height: 240px;
  display: flex;
}

.front-overal-paper-contact .content .help-links a {
  color: #737781 !important;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 10px;
}

.front-overal-paper-contact .content .help-links small {
  color: #44566c !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 20px;
}

.front-overal-paper-contact .content .information {
  width: 100%;
  padding: 0px 45px 0px 0px;
}

.front-overal-paper-contact .content .information .options {
  width: 100%;
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.front-overal-paper-contact .content .information .options a {
  width: 33%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #e5e5e5;
  cursor: pointer;
  transition: 0.6s all;
}

.front-overal-paper-contact .content .information .options a:hover {
  background-color: #00b7ff;
}

.front-overal-paper-contact .content .information .options a.active {
  background-image: linear-gradient(to right, #0081ff, #00b7ff);
}
.h100vh {
  height: 100vh;
}
.main-container-contact-form {
  display: flex;
  border-radius: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 75px 45px 45px 45px;
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.3);
  z-index: 2;
  margin-top: -5px;
  position: relative;
}

.main-container-contact-form .input-with-icon {
  height: 50px;
  min-width: 300px;
  flex: 1;
  margin-right: 42px;
  margin-bottom: 20px;
}

.main-container-contact-form .input-with-icon img {
  top: 14px;
  width: 20px;
  height: 20px;
}

.form-button {
  margin-top: 20px;
  width: 200px;
  height: 50px;
}

.form-button button {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #0081ff;
  border-radius: 5px;
  box-shadow: 0 30px 20px 0 rgba(0, 129, 255, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
  width: 100%;
  padding-top: 11px;
  padding-bottom: 11px;
  border-color: transparent;
  outline: none;
  transition: 0.4s all;
  cursor: pointer;
}

.form-button button:hover {
  border-radius: 5px;
  box-shadow: unset;
}

.from-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 41px;
}

.contact-from-number-dp {
  display: flex;
}

.contact-from-number-dp .input-with-icon {
  min-width: 274px;
}

.contact-from-number-dp .drop-down-menu {
  margin-right: 10px;
  width: 125px;
  min-width: unset !important;
}

.contact-from-number-dp .drop-down-menu small {
  left: 5px;
}

/* Dashbord Style */
@import "map.css";

.layout div.item {
  background: #fff;
  box-shadow: 0 0px 16px 0 rgba(169, 194, 209, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  z-index: 99;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: #9b9b9b;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 3px;
  right: 3px;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

#content {
  width: 100%;
}

.react-grid-layout {
}

.layoutJSON {
  background: #ffffff;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}

.react-grid-item {
  box-sizing: border-box;
}

.react-grid-item:not(.react-grid-placeholder) {
  /*
                background: #fff;
                border-radius: 5px;
                box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
                */
}

.react-grid-item.resizing {
  opacity: 0.9;
}

.react-grid-item.static {
}

.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}

.react-grid-item .minMax {
  font-size: 12px;
}

.react-grid-item .add {
  cursor: pointer;
}

.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.itemRow {
  width: 100%;
  height: 100%;
  padding: 10px;
  /*overflow: hidden;*/
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}
.leaflet-container {
  width: 100%;
  height: 100%;
}

.titleItemTable {
  /* position: absolute; */
}

.CardSelect {
  font-size: 14px;
  padding: 3px;
}

.tdSetting {
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  margin-left: 5px;
  background: url("/assets/dots.svg") center;
}

.mapViewFa {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.PieChartInfo {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.PieChartDate {
  /* position: absolute;
  left: 0px;
  top: 0px; */
  font-size: 15px;
  color: #44566c;
  margin-bottom: 20px;
}

i.AVAILABLE {
  background: #09b66d;
}

i.DRIVE {
  background: #0081ff;
}

i.WORK {
  background: #fdbf5e;
}

i.REST {
  background: #ff8a48;
}

i.Unknown {
  background: #ff3d57;
}

i.HOLIDAY {
  background: #44566c;
}

.mapViewDataDiv {
  width: 100%;
  text-align: left;
  font-size: 12px;
  margin-bottom: 10px;
  float: left;
}

.mapViewDataDiv strong {
  width: 50%;
  float: left;
  font-weight: normal;
}

.mapViewDataDiv span {
  width: 50%;
  display: block;
  height: 40px;
  color: #8697a8;
  float: left;
  text-align: left;
  font-size: 15px;
}

.mapZoomOutCustom {
  width: 30px;
  height: 30px;
  background: #fff;
  position: absolute;
  z-index: 999999999;
  top: 120px;
  right: 10px;
  border-radius: 5px;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.mapZoomOutCustom img {
  width: 80%;
}

.mapboxgl-popup-close-button {
  position: absolute;
  right: 0px;
  width: 15px;
  height: 15px;
  top: 0px;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}

.dashboardView {
  padding-top: 10px;
  position: relative;
  padding-bottom: 260px;
}

.customSelect {
  height: 40px;
  padding: 5px 15px;
  background: #fff;
  border-radius: 5px;
  position: relative;
  margin-left: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  cursor: pointer;
}

.customSelectValue {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #44566c;
}

.customSelectValue img {
  margin-left: 5px;
}

.customSelectOptionsView {
  display: none;
  position: absolute;
  width: 140%;
  height: auto;
  background: #fff;
  left: 0;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  border-radius: 5px;
  top: 45px;
  padding: 5px;
}

.customSelectOptionsViewShow {
  display: block;
}

.customSelectOptionsView div {
  color: #44566c;
  text-align: center;
  padding: 2px 0;
  cursor: pointer;
}

.chartPaper {
  width: 100%;
  height: 100%;
  box-shadow: none;
  background: none;
}

.customScrollBar::-webkit-scrollbar,
.tableContainer::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
}

.requestIconStatus {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  background: red;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  justify-content: center;
  display: flex;
  font-size: 21px;
}

.imgBox {
  position: relative;
}

.requestIconStatusManager {
  width: 20px;
  height: 20px;
  right: 5px;
  top: auto;
  bottom: -2px;
  font-size: 14px;
}

.mobileNumberInput {
  display: flex;
  flex: 1;
  height: 100%;
  background-color: #f8fafb;
  border-color: transparent;
  padding: 10px;
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
}

.smallHint {
  color: #44566c;
  font-size: 13px;
  text-align: left;
  margin-bottom: 5px;
}

.mobileNumberInputFa {
  display: flex;

  margin-top: 20px;
}

.mobileInputContainer {
  width: 350px;
  margin: auto;
}

.mobileNumberInputDropDown {
  position: relative;
  background: #f8fafb;
  width: 130px;
  height: 43px;
}

.mobileNumberInputDropDown .input-with-icon img {
  width: 21px !important;
  height: 21px !important;
}

.mobileNumberInputDropDown .drop-down-icon {
  right: 0 !important;
}
.left-10 small {
  left: 20px !important;
}
.deep-sky-blue-text {
  cursor: pointer;
}

.flex-display {
  display: flex;
}

.requestIconStatus img {
  width: 70%;
}

.OPEN {
  background: #007bff;
}

.ACCEPTED {
  background: #09b66d;
}

.REJECTED {
  background: #e63143;
}

.customScrollBar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.customScrollBar::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 10px;
}

.customScrollBar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.TableSelectVehiclType {
  display: flex;
}
.SingleType {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
}
.activeType img {
  border: 2px solid #0081ff;
  padding: 3px;
}
.SingleType img {
  width: 60px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 2px 10px;
  border-radius: 100%;
  padding: 3px;
  background-color: #fff;
  margin-bottom: 10px;
}
.ChartParent {
  display: flex;
  flex: 1;
}
.chartsContainer div:first-child {
  /*height: 100%;*/
}

.chartsContainer canvas {
  /*height: 100% !important;*/
}
.chartSubText {
  width: 100%;
  text-align: center;
  margin-top: -10px;
  color: #44566c;
  white-space: nowrap;
}

.graphItemSmallFa {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.graphItemSmall {
  display: flex;
  flex-direction: row;
  height: 100%;
}

div[class^="Component-root-"] {
  height: 100% !important;
}

.graph-1-data .title {
  color: #44566c;
  font-weight: bold;
  font-size: 20px;
}

.rdrDefinedRangesWrapper {
  width: 160px !important;
}

.graph-1-data .sub-title {
  color: #44566c;
  font-size: 15px;
  margin: 10px 0;
}

.percent {
  font-size: 30px;
  color: #44566c;
}

.percent span {
  font-size: 16px;
}

.graph-center-content {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph-center-content div.subText {
  color: #44566c;
  font-size: 13px;
}

.graph-1-data .average {
  font-size: 24px;
  color: #44566c;
  display: flex;
}

.graph-1-data .average-number {
  background: #09b66d;
  margin-top: 5px;
  border-radius: 10px;
  padding: 6px;
  font-size: 12px;
  color: #fff;
}

.graph-1-data .average .average-icon {
  width: 60px;
  height: 25px;
  margin-top: 5px;
  background-size: 100% auto;
}

.table thead th,
.table tbody td {
  border-bottom: 1px solid #eaedf0;
  position: relative;
}

.progressBar {
  background: #d8d8d8;
  width: 100px;
  margin-left: 10px;
  height: 15px;
}

.tdData {
  font-size: 15px;
  color: #44566c;
}

.tdData span {
  color: #44566c;
  font-size: 15px;
}

.progressBar span {
  height: 100%;
  display: flex;
}

.up span {
  background: #09b66d;
}

.down span {
  background: #ff3d57;
}

.rankNum {
  padding-left: 25px !important;
  width: 60px;
}

.iconTd {
  width: 25px;
}

.graph-1-data .Detail {
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: 15px;
  color: #44566c;
  text-decoration: underline;
}

.jss1 {
  height: 100% !important;
}

.mapboxgl-ctrl-fullscreen {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M24 16v5.5c0 1.75-.75 2.5-2.5 2.5H16v-1l3-1.5-4-5.5 1-1 5.5 4 1.5-3h1zM6 16l1.5 3 5.5-4 1 1-4 5.5 3 1.5v1H7.5C5.75 24 5 23.25 5 21.5V16h1zm7-11v1l-3 1.5 4 5.5-1 1-5.5-4L6 13H5V7.5C5 5.75 5.75 5 7.5 5H13zm11 2.5c0-1.75-.75-2.5-2.5-2.5H16v1l3 1.5-4 5.5 1 1 5.5-4 1.5 3h1V7.5z'/%3E%3C/svg%3E");
  display: none !important;
}

.mapboxgl-ctrl-shrink {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5 16c-1.75 0-2.5.75-2.5 2.5V24h1l1.5-3 5.5 4 1-1-4-5.5 3-1.5v-1h-5.5zM13 18.5c0-1.75-.75-2.5-2.5-2.5H5v1l3 1.5L4 24l1 1 5.5-4 1.5 3h1v-5.5zm3-8c0 1.75.75 2.5 2.5 2.5H24v-1l-3-1.5L25 5l-1-1-5.5 4L17 5h-1v5.5zM10.5 13c1.75 0 2.5-.75 2.5-2.5V5h-1l-1.5 3L5 4 4 5l4 5.5L5 12v1h5.5z'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl-zoom-in {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl-zoom-out {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl-compass {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23ccc'/%3E%3C/svg%3E");
}

.barChart-data {
  padding: 5px;
  padding-top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.chartBarFa {
  position: absolute;
  width: 100%;
  left: 0;
}

.tableVehicle {
  /*display: block;*/
  /*overflow-x: scroll;*/
  /*overflow-y: visible;*/

  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.tableVehicle tbody {
  background: #fff;
}

.ov-none {
  overflow: hidden;
}

.pieChart {
  /*top: calc(-50% + 70px);*/
  /*position: absolute;*/
}

.tableVehicle td,
.tableVehicle th {
  border-top: 0;
  text-align: left;
  white-space: nowrap;
}

.tableVehicle thead th {
  text-transform: uppercase;
  color: #44566c;
  font-weight: 500;
  font-size: 0.9em;
  white-space: nowrap;
}

.tableVehicle thead th div.thCol {
  display: flex;
}

.sortByItem {
  cursor: pointer;
}

.tableVehicle td {
  color: #44566c;
}

.tableRow td:nth-child(odd) {
  color: #8697a8;
}

.tableVehicle tbody tr:nth-child(odd) {
  background: #f8fafb;
}

i.active {
  background: #09b66d;
}

i.deactivate {
  background: #ff3d57;
}

i.pending {
  background: #fdbf5e;
}

.colDetails {
  width: 20px;
  height: 5px;
  cursor: pointer;
  background: url("/assets/dotsLight.svg") center no-repeat;
}

.colDetailsFa {
  height: 20px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  list-style: none;
  outline: none;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.25);
  height: 40px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.pagination > .active > a {
  background-color: #47ccde;
  border: none;
  box-shadow: 0 8px 16px 0 rgba(0, 129, 255, 0.2);
  color: #fff;
}

.pagination > li {
  height: 100%;
  display: flex;
}

.pagination > li > a {
  padding: 10px;
  outline: none;
  cursor: pointer;
}

.pagination .next,
.pagination .previous {
  background: #0081ff;
  height: 100%;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.pagination .next a {
  background: url("/assets/ArrowRight.svg") center no-repeat;
}

.pagination .previous a {
  background: url("/assets/ArrowLeft.svg") center no-repeat;
}

.previous.disabled a {
  background: url("/assets/ArrowLeftBlack.svg") center no-repeat !important;
}

.next.disabled a {
  background: url("/assets/ArrowRightBlack.svg") center no-repeat !important;
}

.pagination .next a,
.pagination .previous a {
  color: #fff;
  padding: 15px;
  height: auto;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #0081ff;
  outline: none;
}

.pagination .disabled {
  background: #fff;
}

.pagination > li > a,
.pagination > li > span {
  color: #5a7089;
  font-family: Roboto;
  font-size: 0.8em;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.tableFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tableFooterDiv {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.tableFooterDiv select {
  width: 90px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  background-color: #ffffff;
  margin-left: 10px;
}

.itemsCount {
  color: #8697a8;
  font-size: 15px;
}

.showRows {
  justify-content: flex-start;
}

.paginationDiv {
  justify-content: flex-end;
}

.createBtn {
  width: 35px;
  height: 35px;
  box-shadow: 0 8px 16px 0 rgba(0, 129, 255, 0.2);
  border-radius: 100%;
  margin-left: 20px;
  background: url("/assets/plus-white.svg") no-repeat;
  background-size: 50%;
  background-position: center;
  background-color: #0081ff;
  cursor: pointer;
  margin-top: 2.5px;
}

.addRequestBtn {
  position: absolute;
  top: 11px;
  right: 320px;
}

.ColorSelect {
}

.colorInput {
  width: 100%;
  padding: 10px;
}

.colorInput span {
  width: 100%;
  padding: 10px;
  display: block;
}

.colorsList {
  display: flex;
  flex-wrap: wrap;
  background: #f8fafb;
  padding: 5px;
}

.colorsList div {
  margin: 2px;
}

.search-input-table .input-with-icon {
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  border-radius: 20px;
}

.search-input-table .input-with-icon img {
  width: 22px;
  height: 22px;
  left: 10px;
  top: 8px;
}

::placeholder {
  color: #8697a8;
}

:-ms-input-placeholder {
  color: #8697a8;
}

::-ms-input-placeholder {
  color: #8697a8;
}

.emptyTable {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 130px;
  color: #a6b1bd;
}

#center-axis-container g path {
  fill: #ff8a48 !important;
}

.colEditPopup {
  width: 100%;
  height: 100%;
  background: rgba(68, 86, 108, 0.15);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  z-index: 999999999;
  justify-content: center;
  align-items: center;
}
.colFilter {
  max-height: 350px;
  overflow-y: scroll;
}

.thDetails {
  position: relative;
}

.colFilterIcon {
  width: 25px;
  height: 25px;
  margin-top: -5px;
  margin-right: 5px;
}

.editView {
  position: fixed;
  left: 0px;
  top: 0;
  width: 100vw;
  height: 100%;
  background: rgba(68, 86, 108, 0.4);
  z-index: 999999999999;
}

.editForm {
  width: 450px;
  height: 100vh;
  background: #fff;
  float: right;
  padding: 20px 30px;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  flex-direction: column;
  display: flex;
}

.editForm input[type="color"] {
  width: 50px;
  height: 50px;
  padding: 3px;
}

.header-account-profileImage {
  overflow: hidden;
  width: 40px;
}

.header-account-profileImage img {
  min-height: 100%;
  min-width: 100%;
}

.dropDownLoading {
  display: flex;
  justify-content: center;
  padding: 5px;
  color: #8697a8;
}
.dropDownSearchInput {
  padding-left: 40px !important;
}
.required {
  color: red;
}

.noDataAvailable {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.tagTable {
  padding: 3px 5px;
  border-radius: 5px;
  background: #efeeee;
  box-shadow: -2px 2px #b5b5b5;
  float: left;
  margin: 5px 5px 0 0;
  font-size: 16px;
}

.tagTable img {
  width: 25px;
  margin-left: 10px;
  background: #ccc;
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.editForm .title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: bold;
  color: #44566c;
  margin-bottom: 20px;
}

.editRowTitle {
  text-transform: capitalize;
  font-size: 15px;
  color: #8697a8;
  margin-bottom: 10px;
}
.editRowTitle strong {
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
}
.editRow {
  margin-bottom: 10px;
}

.editRow input {
  border: none;
  background: #f8fafb;
  width: 100%;
  padding: 10px;
  font-family: Roboto;
  font-size: 15px;
  color: #44566c;
}
.groupView {
  width: 350px;
}

.groupViewTitle {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  color: #44566c;
  margin-bottom: 20px;
}
.editFormSave {
  width: 100%;
  /* position: absolute; */
  background: #fff;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  /* padding: 10px; */
  /* padding-bottom: 20px; */
  margin-top: 50px;
}

.rowTagItem {
}

.rowTagItem span {
  padding: 10px;
  border-radius: 3px;
  background: #0c5460;
  color: #fff;
  margin-right: 10px;
}

.editAction {
  padding: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 15px;
  border: none;
  box-shadow: none;
}

.editActionSave {
  background: #0081ff;
  color: #fff;
}

.editActionSave img {
  margin-right: 5px;
}

.editActionCancel {
  background: rgba(0, 128, 255, 0.2);
  color: #0081ff;
}

.editActionCancel {
  background: rgba(0, 128, 255, 0.2);
  color: #0081ff;
}

.editFormCreate {
  justify-content: flex-end;
}

.editActionDelete {
  color: #ff3d57;
  border-radius: 5px;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #ff3d57;
  margin-left: 0 !important;
}

.closeParent {
  display: flex;
  flex: 1;
}

.tableContainer {
  /*max-width:  calc( 100vw - 280px );*/
  /*overflow: scroll;*/
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.tableViewContainer {
  /* flex: 1; */
  overflow: scroll;
  /* max-width: calc(100vw - 240px); */
}
.widgetsPermissionList {
  background: #fff;
  display: flex;
  width: 80%;
  flex-direction: column;
  padding: 10px;
}
.widgetsPermissionSection {
  flex-direction: column;
  display: flex;
}
.widgetsPermissionWidgets {
  display: flex;
  flex-wrap: wrap;
}
.widgetsPermissionList .widget {
  display: flex;
  flex-direction: column;
  flex: 48%;
  max-width: 48%;
  margin-bottom: 30px;
  margin-right: 2%;
  padding: 20px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  justify-content: center;
  background: #eff4f8;
  border-radius: 10px;
  color: #44566c;
  text-align: center;
}
.allows {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.allows div {
  display: flex;
  flex: 1;
  justify-content: center;
}
.iconAllow {
  background: red;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  cursor: pointer;
}
.iconAllow::before {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  display: block;
  background: url("/assets/icon-basic-cross-white.svg") center no-repeat;
  background-size: 100%;

  width: 100%;
  height: 100%;
}
.iconAllowCheck::before {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  display: block;
  background: url("/assets/check.svg") center no-repeat;
  background-size: 60%;
  width: 100%;
  height: 100%;
}
.widgetsPermissionList .widget img {
  width: 100%;
  margin-bottom: 20px;
}
.iconAllowCheck {
  background: #09b66d !important;
}
.widgetsPermissionSection h2 {
  font-size: 23px;
  color: #44566c;
  margin-bottom: 20px;
}
.widgetsPermissionSelect {
  margin-bottom: 30px;
}
.widgetsPermissionSelect h2 {
  font-size: 23px;
  color: #44566c;
}
.widgetsPermissionSelect span {
  font-size: 15px;
  color: #8697a8;
}
.selectRow span {
  display: block;
  float: left;
}
.selectRow .title {
  margin-left: 15px;
  margin-right: 8px;
  text-align: left;
}

.loader {
  position: relative;
}

.lds-ripple {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 190px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #007bff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.lds-ripple div:nth-child(3) {
  animation-delay: -1s;
}

.lds-ripple div:nth-child(4) {
  animation-delay: -1.5s;
}

@keyframes lds-ripple {
  0% {
    top: 90px;
    left: 90px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 180px;
    height: 180px;
    opacity: 0;
  }
}

.Toastify__toast-container {
  z-index: 9999999999999 !important ;
}
.Toastify__toast--success {
  background: #09b66d !important;
}
.alert-danger {
  background-color: #ff3d57 !important;
  color: #fff !important;
}
.content-container {
  /*position: relative; for Table Edit View*/
}

.InputError {
  border: 2px solid #ff354b !important;
}

.searchResBox {
  width: 100%;
  padding: 10px;
  background: #f8fafb;
  height: 150px;
  overflow-y: scroll;
  border: 2px solid #dbdcdd;
  margin-bottom: 30px;
  margin-top: 10px;
  border-radius: 10px;
}

.searchResBoxItem {
  padding: 5px;
  border-bottom: 2px solid #dbdcdd;
  cursor: pointer;
}

.searchResBoxItemActive {
  background: #bbbcbd;
}
.LoadingButton {
  cursor: pointer;
  border: none;
  outline: none;
}
.login-form-inputs-container {
  width: 300px;
}

/*.dashboardSection .react-grid-item{*/
/*    transform: scale(.5);*/
/*}*/

/*.OverView{*/
/*  width: 100%;*/
/*  height: 500px;*/
/*  display: flex;*/
/*  background: red;*/
/*}*/
/*.overViewSection{*/
/*  padding: 10px;*/
/*  background: black;*/
/*  display: flex;*/
/*  flex: 1;*/
/*  margin: 10px;*/
/*  width: 30%;*/
/*}*/

.companyLogoEdit {
}

.companyLogoEdit .input-with-label {
  height: 120px !important;
}

.companyLogoEdit .account-image-section,
.companyLogoEdit .picked-image-container,
.companyLogoEdit .custom-file-upload,
.companyLogoEdit .image-picker-button-container {
  width: 100% !important;
  height: 120px !important;

  border-radius: 5px;
}

.ErrorInputBorder {
  border: #e01730 2px solid !important;
}

.ErrorSpan {
  color: #e01730;
}

.inviteView {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999999999999;
  background-color: #7f7f7f94;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.copyLink button {
  background: none;
  border: none;
  color: #0081ff;
  cursor: pointer;
}

.inviteViewContent {
  background: #fff;
  padding: 20px;
  position: relative;
  max-height: 90%;
  width: 600px;
}

.inviteViewContent .title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: bold;
  color: #44566c;
}

.inviteForm {
  width: 550px;
  display: flex;
  flex-direction: column;
  height: 350px;
}

.inviteLinkFa {
  width: 100%;
  word-wrap: break-word;
}

.inviteForm .input-with-icon img {
  width: 20.7px !important;
  height: 18.7px !important;
}

.inviteForm span {
  font-family: Roboto;
  font-size: 15px;
  color: #8697a8;
}

.inviteForm strong {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  color: #44566c;
  margin-bottom: 20px;
}

.inviteForm textarea {
  background: #f8fafb;
  border: none;
  height: 250px;
  max-height: 150px;
  min-height: 150px;
  padding: 20px;
}

.sendInvite {
  padding: 10px 30px;
  color: #fff;
  background: #0081ff;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.inviteAction {
  display: flex;
}

.copyLink span {
  cursor: pointer;
}

.copyLink {
  display: flex;
  align-items: center;
  flex: 1;
  color: #0081ff;
}

.copyLink img {
  margin-right: 10px;
}

.inviteForm textarea:focus {
  outline: none;
}

.inviteLink span {
  color: #44566c;
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
}

.inviteLink {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  /* background: rgb(248, 250, 251); */
  padding: 10px;
  cursor: pointer;
  /* border-top: 1px solid #b3c0ce; */
}
.inviteLinkMobile img {
  width: 100%;
}

.calendarFa {
  height: 100%;
  display: flex;
  padding: 5px;
  padding-bottom: 20px;
  position: relative;
}

.OpenRequests {
  width: 300px;
  margin-top: 62px;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  margin-left: 5px;
  margin-right: 5px;
  overflow-y: scroll;
  border-radius: 7px;
  max-height: 100vh;
}

.OpenRequestsUser {
  margin-top: 61px;
}

.OpenRequests .title {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  color: #44566c;
  text-align: center;
  padding: 7px 20px;

  border-bottom: 2px solid #eaedf0;
}

.CalendarView {
  display: flex;
  flex: 1;
}

.rbc-calendar {
  flex: 1;
}

.rbc-month-view {
  padding: 0px;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  border: none;
  border-radius: 7px;
}

.rbc-toolbar {
  /*display: block;*/
  padding: 10px;
  justify-content: center;
  /*width:  calc( 100% - 300px );*/
  /*background: red;*/
}

.rbc-toolbar button {
  background: #fff;
  cursor: pointer;
  border: none;
  padding: 10px 15px;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background: #1d80fe;
  color: #fff;
}
.rbc-toolbar {
  padding-right: 10% !important;

  justify-content: space-between;
}
/*.UserCalendarToolbar .rbc-toolbar {*/
/*  padding-right: 60px !important;*/
/*}*/

.CalendarViewBarOpen .rbc-toolbar {
  padding-right: 40% !important;
}
.rbc-toolbar-label {
  font-weight: bold;
}

.fileDocument {
  position: absolute;
  bottom: 20px;
  background: #eaedf0;
  border-radius: 5px;
  padding: 7px;
}

.rbc-toolbar *:focus {
  outline: none;
}

.rbc-header {
  border-left: 2px solid #eaedf0;
  display: flex;
  justify-content: center;
  padding-top: 13px;
  padding-bottom: 13px;
  font-weight: normal;
  color: #8697a8;
}

.rbc-month-row + .rbc-month-row {
  border-top: 2px solid #eaedf0;
}

.RequestsItem {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #eaedf0;
  position: relative;
}

.RequestsItem .imgBox {
  background: #e8e8e8;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 5px;
}

.RequestsItemHeader {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
}

.requestArrow {
  position: absolute;
  top: 25px;
  right: 20px;
  width: 20px;
  height: 20px;
  background: url("/assets/icon-basic-arrow-right.svg") center no-repeat;
  background-size: 100% auto;
  cursor: pointer;
}

.requestArrowDown {
  background: url("/assets/icon-basic-arrow-down.svg") center no-repeat;
  background-size: 100% auto;
}

.requestDataTitle {
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  color: #44566c;
}

.requestDataDay i {
  display: block;
  width: 8px;
  height: 8px;
  background: red;
  margin-right: 5px;
  border-radius: 100%;
}

.RequestDate {
  color: #8697a8;
  font-family: Roboto;
  font-size: 13px;
}

.RequestType {
  font-family: Roboto;
  font-size: 13px;
  color: #8697a8;
}

i.PAID {
  background: "#0081FF";
}

i.UNPAID {
  background: "#22CCE2";
}

i.SPECIAL {
  background: "#09B66D";
}

i.Sick {
  background: "#FF8A48";
}

.requestData {
  padding: 10px;
}

.none {
  display: none;
}

.requestDataNote {
  background: #f8fafb;
  padding: 10px;
  color: #44566c;
  margin-top: 5px;
  min-height: 70px;
  margin-bottom: 20px;
}

.requestDataNoteInput {
  border: none;
  width: 100%;
}

.inviteResTable {
  display: flex;
  flex-direction: column;
  background: #f8fafb;
  max-height: 100%;
  overflow-y: scroll;
}

.inviteResTable .inviteResTableRow:nth-child(odd) {
  background: #fff;
}

.inviteResTableRow {
  padding: 15px;
}

.inviteResTableRowData {
  display: flex;
  flex: 1;
}

.inviteResTableRow .divCol {
  display: flex;
  flex: 1;
  padding-left: 10px;
}

.inviteResTableRow img {
  width: 30px;
}

.inviteResTableRow .Pending {
  background: #ff3d57;
}

.inviteResTableRow {
  position: relative;
}

.inviteResTableRowStatus {
  position: absolute;
  right: 10px;
  top: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.inviteResTableRow .Accepted {
  background: #09b66d;
}

.inviteResTableRow .Accepted img {
  width: 15px;
}

.RequestBtns {
  display: flex;
  justify-content: flex-end;
}

.RequestBtn {
  padding: 8px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.RequestBtnAccept {
  background: #0081ff;
  color: #fff;
  font-size: 15px;
}

.RequestBtnReject {
  border: 2px solid #ff3d57;
  color: #ff3d57;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 15px;
}

.toolbar-container {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.back-next-buttons {
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  border-radius: 5px;
  margin-left: 2px;
}

.toolbar-container button {
  background: none;
  border: none;
  padding: 5px 20px;
}

.btn-next,
.btn-back {
  padding: 0px 15px !important;
  font-size: 25px;
  color: #44566c;
}

.btn-current {
  text-transform: capitalize;
  color: #44566c;
}

.requestLink {
  position: absolute;
  right: 10px;
  top: 11px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  background: #fff;
  width: 300px;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
}

.requestLinkUser {
  top: 8px;
}

.requestLink span {
  display: flex;
  flex: 1;
  align-items: center;
}

.requestLink i {
  display: block;
  width: 18px;
  height: 18px;
  margin-top: 4px;
  background: url("/assets/icon-basic-arrow-down.svg") center no-repeat;
  background-size: 100% auto;
}

.requestLink i.closeItem {
  background: url("/assets/icon-basic-arrow-right.svg") center no-repeat;
  background-size: 100% auto;
}

.DateInfo {
  display: flex;
}

.DateInfoCol {
  display: flex;
  flex-direction: column;
}

.DateInfoColData {
  background: #f8fafb;
}

.DateInfoCol span {
  color: #8697a8;
}

.DateInfoCol {
  display: flex;
  padding: 3px;
}

.DateInfoColData {
  padding: 6px;
  border-radius: 6px;
  font-family: Roboto;
  font-size: 15px;
  position: relative;
  height: 40px;
}

.DatePickerRequest {
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 40px;
}

.requestDataTitle {
  margin-top: 10px;
}

.flex1 {
  display: flex;
  flex: 1;
}

.requestFile {
  display: flex;
  flex: 1;
  padding: 10px;
  margin-top: 5px;
  border-radius: 6px;
  background: #f8fafb;
  flex-direction: column;
}

.LinkInvite {
  padding: 5px;
  word-wrap: break-word;
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
}

.RequestTypeFa {
  display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.RequestTypeItem {
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 5px;
  margin: 5px;
  color: #44566c;
  cursor: pointer;
}

.DateInfoCol input {
  background: transparent;
  border: none;
  padding: 6px;
}

.RequestTypeItem i {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-right: 10px;
  border: #eaedf0 2px solid;
}

.RequestTypeItem i.active {
  background: #0081ff;
}

.sendRequest {
  padding: 10px 30px;
  color: #fff;
  background: #0081ff;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  display: block;
  width: 200px;
  float: right;
  margin-top: 10px;
}

.num_days_input {
  display: block;
  width: 80px;
}

.langMenu {
  position: relative;
}

.langMenu-drop-menu {
  display: none;
  position: absolute;
  right: 0px;
  top: 30px;
  width: 183px;
  transition: 0.4s height;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.3);
  z-index: 999999999;
}

.langMenu:hover .langMenu-drop-menu {
  display: block;
}

.langMenuHome .header-language-icon {
  float: left !important;
}

.langMenuHome .header-language-container {
  height: 25px !important;
}

.profile-fa {
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1),
    0 4px 8px 0 rgba(169, 194, 209, 0.25);
  border-radius: 10px;
}

.account-fa {
  width: 410px !important;
}

.profile-fa .right-section {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.updateBtn {
  float: right;
  padding: 10px 30px;
  background: #0081ff;
  margin: 14px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.updateBtn img {
  margin-right: 5px;
}

.popupBg {
  position: fixed;
  left: 0px;
  top: 0;
  width: 100vw;
  height: 100%;
  background: rgba(68, 86, 108, 0.4);
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  padding: 10px 30px;
  padding-bottom: 50px;
  background: #fff;
  border-radius: 5px;
  position: relative;
}

.billingHistory {
  display: block;
  padding: 10px;
  height: 180px;
  overflow-y: scroll;
}

.billingHistoryItem {
  width: 100%;
  height: 70px;
  border-bottom: 2px solid #eaedf0;
  position: relative;
  display: flex;
  align-items: center;
}

.billingHistoryItem img {
  width: 50px;
}

.billingHistoryItemInfo {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.billingHistoryItemInfo strong {
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}

.billingHistoryItemInfo .ItemStatus {
  color: #8697a8;
  font-family: Roboto;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}

.popup-content .add {
  width: 100%;
  background: #0081ff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.popup-content .add img {
  width: 22px;
  margin-right: 10px;
}

.popup-content-language {
  width: 350px;
}

.popup-content .title {
  color: #44566c;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.profileSide {
  display: flex;
  flex: 1;
  width: 100%;

  flex-direction: column;
}

.profileSideItem {
  width: 100%;
}

.profileSideItemHeader {
  background: #fff;
  position: relative;
  width: 100%;
  padding: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #44566c;
  margin-top: 10px;
}

.profileSideItemHeader img {
  width: 17px;
}

.LicensesIcon {
  width: 35px;
}

.iconSideProfile {
  margin-right: 10px;
}

.profileSideItemLink {
  padding: 6px !important;
  color: #44566c;
  position: relative;
  font-size: 15px;
  margin-left: 15px;
  cursor: pointer;
  align-items: center;
  display: flex;
  margin-top: 5px;
}

.profileSideItemLink img {
  width: 25px;
  margin-right: 10px;
}
.profileSideItemHeader img.open,
.profileSide .delete {
  position: absolute;
  right: 10px;
}

.profileSide .delete {
  display: none;
}

.profileSideItemLink:hover .delete {
  display: block;
}

.profile-fa .input-with-label input {
  min-width: 100% !important;
}

.profileSideColTitle {
  padding: 10px 0;
  display: flex;
  width: 100%;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  color: #44566c;
}
.popup-content .input-with-label-container {
  width: 100% !important;
}
.profilePassChange button {
  font-size: 12px;
  margin-left: 10px;
}

.profile-fa .bar-right-section {
  padding: 30px 0;
  background: #f8fafb;
}

.wid100 .input-with-label-container {
  width: 100%;
}

.profileInputRows {
  display: flex;
}

.profileInput {
  background-color: #f8fafb;
  border-radius: 5px;
  position: relative;
  margin-top: 12px;
  padding-left: 16px;
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
  border: none;
  height: 40px;
  width: 31%;
  margin-right: 2%;
}

.inputColTitle {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  flex: 1;
}

.inputColTitle span {
  color: #8697a8;
  font-family: Roboto;
  font-size: 15px;
}

.inputColTitle input {
  margin-top: 3px;
  width: 100%;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/*.profileColRow{*/
/*    align-self: end;*/
/*}*/
/*.profileSide{*/
/*    width: 50%;*/
/*}*/
.langMenuHome {
  margin: 0 !important;
  display: block !important;
}

.langMenu-drop-menu button {
  text-align: center;
  background: transparent;
  outline: none;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #44566c;
  padding: 10px;
  border: none;
  width: 100%;
  cursor: pointer;
}

.CountOfOpenReq {
  background: #0081ff;
  border-radius: 100%;
  margin-right: 10px;
  color: #fff;
  font-weight: 200;
  padding: 0 7px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeLang {
  background: #0081ff !important;
  color: #fff !important;
}

.header-drop-menu button:hover {
  background-color: #7bb7ff;
  color: white;
}

.ErrorMassage {
  color: #b21f2d;
}
.switchRole {
  font-family: Roboto;
  color: #44566c;
  padding: 35px !important;
  position: relative;
  border-radius: 3px;
}
.account-detailCol {
  flex-direction: column;
  flex: 1;
}
.switchRole div.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}
.switchRole .input-with-label-container small,
.switchRole span {
  color: #8697a8;
  font-size: 15px;
}
.switchRole .updateBtn {
  margin-right: 0 !important;
}
.switchRoleFA {
  background-color: rgba(134, 151, 168, 0.5);
  position: fixed;
  top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  z-index: 9999999999;
  height: 100vh;
  width: 100%;
}
.divInput {
  width: 100%;
  height: 40px;
  background-color: #f8fafb;
  border-radius: 5px;

  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DatePickerLic {
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 40px;
  width: 250%;
}
.popup-content {
  top: -60px;
}
.divInput .react-calendar {
  position: absolute;
  left: 0;
}
.groupsProfile {
  padding: 8px;
}
.groupsProfile span {
  padding: 5px 10px;
  background: #dadcdd;
  margin-right: 5px;
  color: #44566c;
  border-radius: 3px;
}
.noBills {
  text-align: center;
  width: 100%;
  display: block;
  color: #bec4c7;
}
.header-account-container {
  cursor: pointer;
}
.tableRowActive {
  background: #dedede !important;
}
.app_version {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  color: #737781;
}
