.header-side-container {
  width: 100%;
}

.content-container {
  /*overflow-y: scroll;*/
  height: 100%;
  padding-top: 64px;
  background: #f8fafb;
}

.header-content-container {
  height: 100vh;
  max-height: 100vh;
  transform: translateZ(0px);
}
.header-content-container-desktop {
  width: calc(100% - 240px);
}
.header-content-container-mobile {
  width: calc(100% - 60px);
}
.header-container {
  padding: 12px 30px 12px 30px;
  width: 100%;
  background-color: #ffffff;
  height: 64px;
  min-height: 64px;
  position: fixed;
  z-index: 999999999;
  border-bottom: solid 1px #eaedf0;
}
.header-container-desktop {
  width: calc(100% - 240px);
}
.header-container-mobile {
  width: calc(100% - 60px);
}
.nav-container {
  position: relative;
  max-width: 240px;
  width: 240px;
  min-width: 240px;
  height: 100vh;
  max-height: 100vh;
  overflow: scroll;
  background-color: #ffffff;
  transition: max-width 0.6s, min-width 0.6s, width 0.6s;
  border-right: solid 1px #eaedf0;
}

.nav-container-mobile {
  position: relative;
  max-width: 60px;
  width: 60px;
  min-width: 60px;
  height: 100vh;
  max-height: 100vh;
  overflow: scroll;
  background-color: #ffffff;
  transition: max-width 0.6s, min-width 0.6s, width 0.6s;
  border-right: solid 1px #eaedf0;
}

.page-container {
  padding: 40px 30px 30px 30px;
  background: #f8fafb;
}

.header-container .input-with-icon {
  margin-bottom: 0px;
}

.search-input-container {
  width: 290px;
  margin-left: 56px;
}

.header-account-circle {
  min-width: 40px;
  height: 40px;
  text-align: center;
  background-color: #0081ff;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 20px;
}

.header-account-text {
  min-width: 75px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
  margin-left: 15px;
}

.icon-account-container {
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-left: 15px;
}

.icon-account-container img {
  width: 20px;
  height: 20px;
}

.header-notification-container {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.header-notification-container img {
  width: 25.9px;
  height: 25.9px;
}

.header-language-icon {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.header-expand-container button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.header-expand-container img {
  width: 20.9px;
  height: 20.9px;
}

.header-language-icon img {
  width: 26.9px;
  height: 26.9px;
}

.header-account-container button {
  border: none;
  outline: none;
}

.header-account-container button:hover {
  background-color: aliceblue;
}

.header-notification-main button:hover {
  background-color: aliceblue;
}

.language-header-main button:hover {
  background-color: aliceblue;
}

.header-expand-container button:hover {
  background-color: aliceblue;
}

.language-header-main button {
  border: none;
  outline: none;
}

.language-header-main {
  margin: 0px 15px 0px 15px;
  padding: 0px 15px 0px 15px;
  border-right: 1px solid #bfbfbf6b;
  /* border-left: 1px solid #bfbfbf6b; */
}

.header-notification-main button {
  border: none;
  outline: none;
}

.header-expand-container button {
  border: none;
  outline: none;
}

.header-notification-main {
  margin: 0px 15px 0px 15px;
  padding: 0px 15px 0px 15px;
  border-right: 1px solid #bfbfbf6b;
  /* border-left: 1px solid #bfbfbf6b; */
}

.notification-email {
  margin-right: 20px;
}

.header-language-container {
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-left: 5px;
}

.header-language-container img {
  width: 20px;
  height: 20px;
}

.side-header {
  max-width: 200px;
  height: 41px;
  margin: 20px auto 2px auto;
}

.side-header img {
  width: 200px;
  height: 41px;
}

.side-header img.side-header-logo {
  width: 30px;
  height: 41px;
  margin-left: 16px;
}

.side-items-container {
  width: 100%;
}

.side-items-first-element {
  transition: background-color 0.3s, padding 0.3s;
  position: relative;
  height: 48px;
  padding: 15px 20px 15px 20px;
  cursor: pointer;
}

.side-items-first-element:hover {
  background-color: aliceblue;
}

.side-items-first-element:hover .side-item-active-left {
  background-color: #0081ff8c;
}

.side-items-first-element small {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 15px;
  color: #44566c;
}

.side-items-first-element span {
  font-family: Roboto;
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 5px;
  color: #44566c;
}

.side-items-first-element img {
  height: 25px;
  width: 25px;
}

.side-item-active-left {
  transition: background-color 0.3s;
  height: 48px;
  position: absolute;
  left: 0;
  width: 4px;
  background-color: #0081ff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.search-input-container img {
  /*top: 9px;*/
}

.sidemenu-expand-icon {
  position: absolute;
  right: 12px;
}

.bullet-active {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-left: 8px;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #44566c;
}

.bullet-de-active {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-left: 8px;
  transition: background-color 0.3s, border-color 0.3s;
  border: solid 2px #8697a8;
}

.side-items-second-element {
  transition: color 0.3s, height 0.5s, background-color 0.3s, padding 0.3s;
  position: relative;
  height: 48px;
  padding: 15px 20px 15px 20px;
  cursor: pointer;
  overflow: hidden;
}

.side-items-second-element:hover {
  background-color: aliceblue;
}

.side-items-second-element small {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 15px;
  color: #44566c;
}

.side-items-second-element {
  transition: color 0.3s, height 0.5s, background-color 0.3s, padding 0.3s;
  position: relative;
  height: 48px;
  padding: 15px 20px 15px 20px;
  cursor: pointer;
  overflow: hidden;
}

.side-items-second-element:hover {
  background-color: aliceblue;
}

.side-items-second-element small {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 15px;
  color: #44566c;
}

.badge-side-items {
  background-color: #ff3d57;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  position: absolute;
  right: 12px;
  padding: 3px;
  border-radius: 50%;
}

.header-drop-menu {
  position: absolute;
  right: 28px;
  top: 55px;
  width: 183px;
  height: 0px;
  transition: 0.4s height;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 16px 0 rgb(206 221 230);
}

.header-drop-menu button {
  outline: none;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #44566c;
  padding: 10px;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  background-color: transparent;
}

.header-drop-menu button:hover {
  background-color: #7bb7ff;
  color: white;
}

.badge-side-items {
  background-color: #ff3d57;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  position: absolute;
  right: 12px;
  padding: 3px;
  border-radius: 50%;
}

.header-drop-menu button {
  outline: none;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #44566c;
  padding: 10px;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  background-color: transparent;
}

.header-drop-menu button:hover {
  background-color: #7bb7ff;
  color: white;
}
.content-container {
  height: 100%;
  padding-top: 64px;
  background: #f8fafb;
}
