.login-desktop-information-section {
  height: 100vh;
  background-color: #e3e6e7;
  width: 65%;
}

.login-form-section {
  height: 100vh;
  background-color: #ffffff;
  box-shadow: -4px 0 8px 0 rgba(169, 194, 209, 0.15);
  position: relative;
}

.login-desktop-information {
  position: absolute;
  bottom: 98px;
  left: 40px;
}

.login-information-h {
  font-family: Roboto;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 29px;
}

.login-information-p {
  max-width: 703px;
  padding-right: 20px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.13;
  letter-spacing: normal;
  color: #ffffff;
}

.login-form-switch-part {
  position: unset;
  text-align: center;
  font-size: 13px;
  bottom: 26px;
  left: 40px;
  font-family: Roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.login-form-inputs-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  width: 300px;
  height: 41px;
  align-self: center;
}

.login-form-inputs-header-img {
  width: 200px;
  height: 41px;
}

.login-form-inputs-header-text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 31px;
}

.signup-form-inputs-header-text {
  font-family: Roboto;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 38px;
}

.login-desktop-information-img {
  width: 700px;
  height: 100%;
}

.forget-password-link {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
}

.login-button {
  margin-top: 41px;
  margin-bottom: 49px;
}

.login-button button {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #0081ff;
  border-radius: 5px;
  box-shadow: 0 30px 20px 0 rgba(0, 129, 255, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
  width: 100%;
  padding-top: 11px;
  padding-bottom: 11px;
  border-color: transparent;
  outline: none;
  transition: 0.4s all;
}

.login-button button:hover {
  border-radius: 5px;
  box-shadow: unset;
}

.or-text {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #eaedf0;
}

.or-line {
  background-color: #eaedf0;
  width: 45%;
  height: 1px;
}

.login-links {
  margin-top: 40px;
}

.login-links a {
  margin-right: 32px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.4);
}

.login-links a img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.signInText {
  text-align: left;
  margin-bottom: 30px;
  font-family: Roboto;
  font-size: 25.7px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
}

.forgot-password-main {
  padding-top: 47px;
  width: 430px;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.2);
  background-color: #ffffff;
}

.forgot-password-lockpad-container {
  width: 70px;
  height: 94.5px;
  align-self: center;
  margin-bottom: 34px;
}

.forgot-password-lockpad-img {
  width: 70px;
  height: 94.5px;
}

.forgot-password-top-text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 17px;
}

.forgot-password-main-bottom {
  background-color: #f8fafb;
  padding-top: 32px;
  padding-bottom: 27px;
  padding-left: 40px;
  padding-right: 40px;
}

.forgot-button {
  margin-top: 8px;
  margin-bottom: 46px;
}

.forgot-password-main-bottom .input-with-icon {
  background-color: #ffffff;
}

.forget-password-link-to-login {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.forget-password-container {
  background-color: #f8fafb;
}
