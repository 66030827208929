.input-with-icon {
  height: 40px;
  background-color: #f8fafb;
  border-radius: 5px;
  position: relative;
  margin-bottom: 16px;
}

.input-with-icon input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-color: transparent;
  padding-left: 46px;
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
}

.input-with-icon textarea {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  padding: 16px;
  min-height: 200px;
}

.input-with-icon img {
  position: absolute;
  width: 15.5px;
  height: 16px;
  left: 16px;
  top: 10px;
}

.checkbox-with-title small {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8697a8;
  margin-left: 8px;
}

.form-header {
  font-family: Roboto;
  font-size: 1.6em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
  margin-bottom: 10px;
  padding-top: 10px;
}

.bread-container {
  margin-top: 32px;
}

.bread-container button {
  padding: 0px;
  min-width: unset;
  text-transform: none;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
}

.bread-items-container {
  display: flex;
  align-items: center;
}

.bread-items-text {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8697a8;
}

.bread-selected {
  color: #44566c;
}

.bread-container {
  margin-top: 32px;
}

.bread-home-icon {
  margin-top: -4px;
}

.bread-arrow-container {
  margin-top: 1px;
}

.bread-arrow-container img {
  width: 14px;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
  color: rgb(220, 228, 239);
}

.section-containers {
  margin-top: 30px;
  width: 100%;
}

.card-containers {
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.26);
  overflow: hidden;
  border-radius: 10px;
}

.sub-card-containers {
  width: 100%;
  padding: 15px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.26);
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 10px;
}

.account-image-section {
  align-self: center;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background-color: #f4f8f9;
  overflow: hidden;
}

.account-image-section img {
  /*width: 80px;*/
  /*height: 60px;*/
  min-width: 100%;
}
.custom-file-upload img {
  min-width: 40px !important;
  min-height: 40px !important;
}

.account-info-row {
  margin-bottom: 20px;
}

.account-info-row img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.account-info-row small {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
}

.card-white-section small {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #44566c;
}

.account-balance {
  align-self: center;
  margin: 10px 0;
  width: 162px;
  height: 40px;
  border-radius: 20px;
  background-color: #00aced;
}

.account-balance img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.account-balance small {
  margin-top: 0px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.card-white-section {
  background-color: #ffffff;
  padding: 15px;
}
.account-setting-logo {
  width: 100%;
  overflow: hidden;
  padding: 5px;
  background: #fff;
  height: 120px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-setting-logo img {
  height: 100%;
}
.card-gray-section {
  background-color: #f8fafb;
  padding: 24px 55px 58px 55px;
}

.card-gray-section-p30 {
  background-color: #f8fafb;
  padding: 30px;
}

.form-information-section {
  margin-bottom: 16px;
}

.form-information-section small {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /*text-transform: capitalize;*/

  color: #8697a8;
}

.form-information-section-header {
  margin-bottom: 15px;
}

.form-information-section-header small {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
}

.form-information-section-header img {
  width: 22px;
  height: 22px;
}

.form-information-section small.information-value {
  color: #44566c;
  font-weight: 500;
}

.form-information-section-header button {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.visa-card-section {
  align-self: center;
}

.visa-card-section img {
  width: 300px;
  height: 200px;
}

.card-main-white-section {
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.26);
}

.payment-card-container {
  max-width: 340px;
  overflow: hidden;
  height: 185px;
  background-color: #f8fafb;
  border-radius: 10px;
}

.payment-card-image {
  background-color: #eef2f3;
  width: 148px;
}

.payment-card-image img {
  width: 55px;
  height: 45px;
}

.payment-card-detail {
  padding: 15px 10px 15px 10px;
}

.payment-card-detail small {
  font-family: Roboto;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  font-weight: normal;
  letter-spacing: normal;
  color: #8596a8;
}

.payment-card-detail small.header {
  color: #44566c;
}

.payment-card-container button {
  outline: none;
  border: none;
  background-color: transparent;
  width: 75px;
  height: 21px;
  background-color: #0081ff;
  border-radius: 5px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin-top: 15px;
}

.payment-card-container button.pay {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  background-color: #ff3d57;
}

.section-containers-header {
  margin-bottom: 35px;
}

.section-containers-header small {
  font-family: Roboto;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  color: #8697a8;
}

.section-containers-header small.header {
  color: #44566c;
  font-size: 20px;
  font-weight: bold;
}

.section-containers-header button {
  outline: none;
  border: none;
  background-color: transparent;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0081ff;
  margin-left: 15px;
  cursor: pointer;
}

.input-with-label {
  height: 40px;
  background-color: #f8fafb;
  border-radius: 5px;
  position: relative;
  margin-top: 12px;
}

.input-with-label-container {
  margin-top: 15px;
}

.input-with-label-container small {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8697a8;
}

.input-with-label input {
  min-width: 350px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-color: transparent;
  padding-left: 16px;
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
}

.image-picker-container {
  position: relative;
  align-self: center;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background-color: #f4f8f9;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.26);
}

.image-picker-button-container {
  opacity: 0;
  z-index: 2;
  transition: 0.4s all;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background: rgba(68, 86, 108, 0.6);
  border-radius: 50%;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  padding: 20px;
}

.custom-file-upload {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  color: white;
  background: transparent;
  cursor: pointer;
}

.picked-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.picked-image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.custom-file-upload img {
  width: 40px;
  height: 40px;
}

.custom-file-upload small {
  color: white;
  font-family: Roboto;
  font-size: 15px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  padding-left: 58px;
  padding-right: 58px;
}

.image-picker-container:hover .image-picker-button-container {
  opacity: 1;
}

.picked-image-container img {
  width: 100% !important;
  height: auto !important;
  border-radius: 0% !important;
}

.dropdown-menu {
  width: 100%;
  border: none;
  max-height: 200px;
  overflow-y: scroll;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.3);
}

.dropdown-menu button {
  cursor: pointer;
  font-family: Roboto;
  font-size: 15px;
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
  padding: 13px 25px 13px 25px;
}

.drop-down-menu {
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.drop-down-menu small {
  position: absolute;
  left: 50px;
  top: 13px;
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
}

.drop-down-menu .drop-down-icon {
  right: 10px;
  left: unset !important;
}
