.DashboardFa {
  position: relative;
}
.ranking {
  padding: 0px;
  background: transparent !important;
  box-shadow: none;
}
.page-container {
  padding: 40px 30px 30px 30px;
  background: #f8fafb;
}
.ranking .page-container {
  padding: 10px !important;
  height: calc(100% - 50px);
  background-color: none;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.justify-content-center {
  justify-content: center;
}
.rankingTable {
  height: calc(100% - 40px);
  overflow: scroll;
}

.graph-1 {
  display: flex;
  flex-direction: row;
}

.graph-1-fa,
.graph-1-data {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.DSHFA {
  position: fixed;
  top: 64px;
  width: 100%;
  margin-left: -30px;
  z-index: 99999;
  transition: all 0.4s;
}
.DSHFA_mobile {
  margin-left: -30px;
}
@media (max-width: 576px) {
  .DSHFA_mobile,
  .DSHFA {
    margin-left: -10px;
  }
}
.dashboardSectionHeader {
  background: #fff;
  width: 100%;
  height: 50px;
  padding-left: 30px;
  align-items: center;
  display: flex;
  border-bottom: 1px #eaedf0 solid;
}

.dashboardHeaderAction {
  cursor: pointer;
  margin-right: 20px;
}

.dashboardHeaderAction img {
  width: 18px;
}

.dashboardHeaderAction span {
  font-family: Roboto;
  font-size: 13px;
  color: #44566c;
  margin-left: 5px;
}

.dashboardHeaderActionDisable span {
  color: #ccc;
}

.widgetLoading {
  width: 100%;
  height: 100%;
  z-index: 99999999;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  position: relative;
}

.widgetError h3 {
  font-weight: 200;
  color: #989898;
}

.popupItems {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(44, 62, 80, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  z-index: 999999999999;
}
.widgetError {
  width: 95%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.itemsContent {
  width: 100%;
  background: #f8fafb;
  border-radius: 3px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1),
    0 4px 8px 0 rgba(169, 194, 209, 0.25);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
}

.menu-categories {
  background: #fff;
  width: 250px;

  height: 100%;
  border-right: 2px solid #eaedf0;
  border-radius: 3px 0 0 3px;
}

.closePopup {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 25px;
  height: 25px;
  background: url("./assets/close.svg") center;
  background-size: 100%;
  cursor: pointer;
}

.menu-categories .title {
  font-family: Roboto;
  font-size: 1.2em;
  font-weight: 500;

  color: #44566c;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

@media (max-width: 1200px) {
  .dashboardView {
    margin-top: 40px;
  }

  .overviewSection {
    width: 100%;
  }

  .dashboardView {
    margin-top: 10px;
    padding-top: 0;
  }

  .dashboardSectionHeader {
    padding-left: 0;
    justify-content: center;
  }

  .dashboardHeaderAction {
    margin-right: 7px;
  }

  .slider a.previousButton {
    left: 40%;
  }

  .slider a.nextButton {
    left: 60%;
  }
}
.side-items-container {
  width: 100%;
}

.side-items-first-element img g {
  fill: red !important;
  background: red;
}

.side-items-first-element img path {
  stroke: #e5e5e5;
  stroke-width: 10px;
}
.align-items-center {
  align-items: center;
}

.side-item-active-left {
  transition: background-color 0.3s;
  height: 48px;
  position: absolute;
  left: 0;
  width: 4px;
  background-color: #0081ff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.side-items-first-element:hover .side-item-active-left {
  background-color: #0081ff8c;
}

.itemViews {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  overflow-y: scroll;
}

.itemViews section {
  width: 100%;
  margin-bottom: 15px;
}

.itemViews section div.items {
  /*overflow-y: scroll;*/
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.viewTitle {
  width: 100%;
  font-size: 1.4em;
  font-weight: bold;
  color: #44566c;
  padding-left: 10px;
}
.itemView {
  border-radius: 10px;
  margin: 10px;
  color: #44566c;
}

.itemView .imgBox {
  display: flex;
  width: 250px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background: #fff;
  padding: 5px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.3);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.titleItem span.title {
  font-size: 1.2em;
  font-family: Roboto;
  font-weight: bold;
  color: #44566c;
}
.titleItem span.subtitle {
  position: absolute;
  left: 6px;
  top: 30px;
  font-size: 1em;
  font-family: Roboto;
  color: #44566c;
}
.titleItemSetting {
  width: 25px;
  margin-left: 10px;
  height: 25px;
  cursor: pointer;
  z-index: 99999999999;
  background: url("./assets/dots.svg") center;
}

.titleItemCalendar {
  display: flex;
  flex-direction: row;
  padding: 10px;
  /* width: 70px; */
  margin-left: 10px;
  border-radius: 5px;
  height: 35px;
  cursor: pointer;
  z-index: 999;
  background: #f8fafb;
  justify-content: center;
  align-items: center;
}
.titleItemDriver {
  right: 125px;
}
.idlingItemPage {
  width: 100%;
  height: 300px;
  position: relative;
}

.titleItemCalendarIcon {
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background: url("./assets/Calendar.svg") center;
}
.titleItemCalendarDate {
  color: #44566c;
  font-size: 15px;
}
.titleItemCalendarDate strong {
  margin: 0 3px;
}
.itemHeaderOption {
  position: absolute;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  right: 0;
  top: 0;
  height: 100%;
}
.titleItemDriverIcon {
  display: block;
  width: 18px;
  height: 18px;
  background: url("./assets/User.svg") center;
  margin-right: 5px;
}

.titleItemGroupIcon {
  background: url("./assets/LocationWidget.svg") center !important;
}
.WidgetDriverList {
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 9px 0 rgb(169 194 209 / 28%);
  border-radius: 10px;
  z-index: 99999999;
  opacity: 1;
  width: 410px;
  height: 300px;
  overflow-y: scroll;
  position: absolute;
  top: 50px;
  right: 35px;
}
.WidgetGroupList {
  height: auto;
  width: 190px;
  right: 225px;
}
.customScrollBar::-webkit-scrollbar,
.tableContainer::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
}
.DriverItem {
  padding: 5px;
  display: flex;
  height: 55px;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
}
.DriverItemSelect {
  background-color: rgba(35, 154, 233, 0.795);
}
.DriverItem:hover {
  background-color: rgba(85, 85, 85, 0.048);
}

.GroupItem {
  padding: 12px;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 3px;
}
.GroupItemSelect {
  background-color: rgba(35, 154, 233, 0.795);
}
.GroupItem:hover {
  background-color: rgba(85, 85, 85, 0.048);
}
.datePickerChart {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 16px 0 rgba(169, 194, 209, 1);
  border-radius: 10px;
  z-index: 99999999;
  opacity: 0.96;
  position: absolute;
  top: 65px;
  right: 0;
}
.DatePickerBtn {
  display: flex;
  margin-left: 160px;
  justify-content: space-between;
}
.DatePickerBtn button {
  margin: 10px;
  padding: 7px;
  width: 120px;
  background: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid;
  font-size: 17px;
  cursor: pointer;
}
.DatePickerBtn button:focus {
  outline: none;
}
.DatePickerBtn .ok {
  color: #6cb7ff;
  border-color: #6cb7ff;
}
.DatePickerBtn .cancel {
  color: #ff3d57;
  border-color: #ff3d57;
}
.actionBtnItem {
  padding: 5px;
  background: #fff;
  width: 155px;
  height: 150px;
  box-shadow: 0 0 16px 0 rgba(169, 194, 209, 1);
  border-radius: 10px;
  z-index: 9999999999999;
  opacity: 0.93;
  position: absolute;
  top: 55px;
  right: 15px;
}
.actionBtnItemAct {
  width: 100%;
  height: 45px;
  padding: 10px;
  cursor: pointer;
}
.SectionSlide {
  padding: 10px 40px;
  background: #fff;
  border: none !important;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: linear 0.5s;
}
.HiddenSection {
  top: 100%;
}
.shownSlide {
  top: 0%;
}
.rdrStaticRange {
  display: flex !important;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.rdrStaticRangeLabel {
  display: flex;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.rdrStaticRanges {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dashboardSectionHidden .react-grid-layout {
  height: 0 !important;
  overflow: hidden;
}
.slider a.previousButton,
.slider a.nextButton {
  top: auto;
  bottom: 20px !important;
  padding: 10px !important;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 8px 16px rgba(169, 194, 209, 1);
  justify-content: center;
  align-items: center;
}
.slideShowDashboard {
  width: 100%;
  height: 100vh;
  background: #f8fafb;
  position: fixed;
  z-index: 9999999999;
  top: 0;
  left: 0;
}

.dashboardSliderActionView {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
  z-index: 999999999999999999;
  bottom: 0;
  background: red;
}

.SingleOverview {
  padding: 20px;
}

.dashboardSliderAction {
  position: fixed;
  display: flex;
  bottom: 20px;
  z-index: 999999999;
}

.SliderActionGroup {
  display: flex;
  flex-direction: row;
  margin-right: 25px;
}
.sectionEditTitle input {
  border: none;
  border-bottom: 2px solid #65819e;
  background: none;
}

.sectionEditTitle div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
  padding: 5px;
}

.sectionEditTitle img {
  width: 22px;
}

.timesSlide div {
  font-family: Roboto;
  font-size: 15px;
  color: #44566c;
  padding: 7px;
}

.timesSlide {
  position: absolute;
  bottom: 52px;
  left: 5px;
  background: #fff;
  border-radius: 10px;
  width: 140px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1),
    0 1px 12px 0 rgba(204, 204, 204, 0.5);
  padding: 10px;
}

.activeTime {
  color: #00b7ff !important;
}

.sliderAction {
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.CountdownFa {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 40px;
  background: #fff;
  color: #0c5460;

  border-radius: 10px;
}

.fsLogoSlideShow {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 12px 40px;
  color: #0c5460;
  border-radius: 10px;
}
.fsLogoSlideShow img {
  width: 120px;
}
.overviewColTitle {
  font-family: Roboto;
  font-size: 27px;
  font-weight: bold;
  font-stretch: normal;
  margin-bottom: 5px;
  display: flex;
}

.overviewColCheckActive {
  background: #44566c;
}
.sectionHeaderHidden {
  display: none;
}
.sectionEditTitle {
  display: flex;
}
.sectionTitle {
  display: flex;
  font-family: Roboto;
  font-size: 1.4em;
  font-weight: bold;
  color: #44566c;
}
.sectionState {
  cursor: pointer;
}

.sectionHeaderSetting {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.datePickerSection {
  top: 30px;
}

.sectionHeaderSettingItem {
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}

.sectionHeader {
  flex: 1;
  display: flex;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 15px;
  position: relative;
}

.overviewColCheck {
  cursor: pointer;
  border: 3px solid #44566c;
  margin-right: 3px;
  margin-top: 5px;
  width: 27px;
  height: 27px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overviewColCheck img {
  width: 80%;
}
.sliderAction {
  cursor: pointer;
  position: relative;
}

.sliderAction img {
  width: 25px;
}
.slider a.previousButton {
  left: 50%;
}

.slider a.nextButton {
  left: 55%;
}

.slider a img {
  width: 30px;
}

.overviewSection {
  padding-bottom: 20px;
  transition: linear 0.4s;
  border-bottom: #eaedf0 solid 2px;
  width: 31.33333333%;
  border-bottom: 0;
  float: left;
  margin: 1%;
}
.itemDrag {
  display: flex;
  justify-content: center;
  align-items: center;
}
.zIndexHigh {
  z-index: 9999999999999999 !important;
}
.zIndexHighSection {
  position: relative;
  z-index: 9999 !important;
}
.dashboardSection {
  padding-bottom: 20px;
  border-bottom: #eaedf0 solid 2px;
  /*transition: ease-in-out 0.4s;*/
  /*transform: scale(0.3);*/
}
.SettingItemSubMenuItem {
  height: 40px;
  display: flex;
  align-items: center;
}

.SettingItemSubMenuItem img {
  margin-right: 5px;
  width: 18px;
}

.SettingItemSubMenuItem span {
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
}
.companyLogoSlideShow {
  width: auto;
  height: 35px;
}

.ViewsItems {
  background: #f8fafb;
  border-radius: 7px;
  box-shadow: 0 8px 43px 0 rgba(143, 155, 163, 0.2);
  padding: 10px;
  transition: ease-in-out 0.4s;
  max-height: 320px;
  overflow: hidden;
}
.origin {
  transform-origin: top;
}
.add-icon {
  width: 50px;
}
.addItem {
  background: #eaedf0 !important;
  cursor: pointer;
  border: 1px solid #44566c;
}

.addItem .mainTitle {
  color: #44566c !important;
  font-size: 70px;
}

.filterRow {
  padding: 10px;
  color: #44566c;
  cursor: pointer;
}

.filterRow input {
  margin-right: 10px;
}
.SearchVehicleStatus {
  position: absolute;
  height: 35px;
  width: 50%;
  background: #f8fafb;
  right: 35px;
  top: -45px;
}
i.vehicleStatus {
  width: 6px;
  height: 6px;
  display: block;
  float: left;
  border-radius: 15px;
  margin-right: 5px;
}

.flexCol {
  display: flex;
  align-items: center;
  justify-content: left;
}

i.active {
  background: #09b66d;
}

i.deactivate {
  background: #ff3d57;
}

i.pending {
  background: #fdbf5e;
}
.filterRowDisable {
  opacity: 0.3;
}
.color-item {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}
.colFilter {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  /*position: absolute;*/
  /*left: -160px;*/
  /*top: 40px;*/
  /* width: 270px; */
  /*box-shadow: 0 0 5px #737373;*/
  z-index: 999999999;
  display: flex;
  flex-direction: column;
}

.ViewsItems .react-grid-layout {
  transform: translate(0, 0) scale(0.295);
  width: 340%;
  transform-origin: 0 0;
}

.SettingItemSubMenuItemDisable span {
  color: #8697a8;
  cursor: auto;
}

.SettingItemSubMenu {
  background: #fff;
  position: absolute;
  right: 0;
  top: 30px;
  width: 220px;
  z-index: 9999999999;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1),
    0 1px 12px 0 rgba(204, 204, 204, 0.5);
}

.itemDrag .mainTitle {
  font-size: 30px;
  cursor: pointer;
}

.dashboardViewOverViewState {
  display: flex;
  transition: ease-in-out 0.4s;
  flex-wrap: wrap;
}
.actionBtnItemAct span {
  color: #44566c;
  font-size: 15px;
}
.actionBtnItemActDisable span {
  color: #ccc;
}
.DriverItem img,
.driverImgName {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  overflow: hidden;
  background: #0081ff;
  margin-right: 10px;
  color: #fff;
}

.titleItemCalendarArrow {
  display: block;
  width: 20px;
  height: 20px;
  background: url("./assets/ArrowDown.png") center;
  background-size: 100% 100%;
}

.itemIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: auto;
  max-width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
}
.itemIcon img {
  width: 100%;
  height: auto;
}
.titleItem {
  width: 100%;
  position: relative;
  padding: 5px;
  height: 40px;
  margin-bottom: 5px;
}

.itemRow .actionBtnItem {
  top: 40px !important;
}

.itemRow .titleItemSetting {
  top: 13px !important;
}
.itemDescView {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  width: 100%;
  height: 100%;
  transition: linear 0.3s;
  display: flex;
  text-align: center;
  padding-top: 40px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  opacity: 0;
}

.itemDescViewText {
  padding: 10px;
  width: 100%;
  text-align: center;
}

.addToDashboardBtn {
  width: 140px;
  height: 35px;
  background: #0081ff;
  position: absolute;
  bottom: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #fff;
  left: 55px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  justify-content: center;
}

.addToDashboardBtn img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.itemView .imgBox:hover .itemDescView {
  opacity: 1;
}

.itemView span.desc {
  font-size: 0.8em;
  display: flex;
  flex: 1;
  justify-content: center;
}
.row-profile-item img {
  width: 50px;
  border-radius: 10px;
  height: auto;
}
.spanTagTable {
  background: #e7f0f5;
  margin-right: 3px;
  padding: 4px;
  border-radius: 3px;
}
.row-vehicle-icon img {
  width: 50px;
  border-radius: 3px;
  height: 50px;
  position: absolute;
  top: 0;
}
.itemRow .form-header {
  font-size: 1.2em !important;
}
.tableSearch {
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
}
.search-input-table {
  width: 350px;
}
.itemRow .search-input-table {
  width: 100%;
}

.itemRow .search-input-table input {
  border: 1px solid #ccc;
  border-radius: 20px;
}
.search-input-table .input-with-icon {
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  border-radius: 20px;
}

.search-input-table .input-with-icon img {
  width: 22px;
  height: 22px;
  left: 10px;
  top: 8px;
}

.tableViewContainer {
  /* flex: 1; */
  overflow: scroll;
  /* max-width: calc(100vw - 240px); */
}
.tableContainer {
  /*max-width:  calc( 100vw - 280px );*/
  /*overflow: scroll;*/
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}
.itemRow .table td,
.itemRow .table th {
  border-top: 0;
  font-weight: 400;
  white-space: nowrap;
  font-size: 1em;
  border-bottom: 1px solid #eaedf0;
}
.tableVehicle {
  /*display: block;*/
  /*overflow-x: scroll;*/
  /*overflow-y: visible;*/

  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.tableVehicle tbody {
  background: #fff;
}
.tableVehicle thead th div.thCol {
  display: flex;
}
.sortByItem {
  cursor: pointer;
}
.thDetails {
  position: relative;
}
.colDetails {
  width: 20px;
  height: 5px;
  cursor: pointer;
  background: url("./assets/dotsLight.svg") center no-repeat;
}
.colDetailsFa {
  height: 20px;
}
.colEditPopup {
  width: 100%;
  height: 100%;
  background: rgba(68, 86, 108, 0.15);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  z-index: 999999999;
  justify-content: center;
  align-items: center;
}
.tableRow td:nth-child(odd) {
  color: #8697a8;
}
.tableRowActive {
  background: #dedede !important;
}
.emptyTable {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 130px;
  color: #a6b1bd;
}

.marker-icon {
  background-position: center;
  background-size: 100% auto;
  border-radius: 50%;
  height: 55px;
  text-align: center;
  transform: rotate(45deg);
  width: 55px;
}
.marker {
  height: 50px;
  width: 50px;
}

.marker-content {
  border-radius: 50% 50% 50% 0;
  left: 50%;
  margin: -15px 0 0 -35px;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  height: 50px;
  width: 50px;
}

.mapboxgl-popup {
  left: -9px !important;
}
.searchResFa {
  position: absolute;
  top: 40px;
  background: #fff;
  width: 100%;
  z-index: 999;
  max-height: 350px;
  overflow-y: scroll;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgb(169 194 209 / 10%);
}
.searchResItemInfo {
  color: #44566c;
  font-size: 12px;
}

.imgSearchFa i {
  position: absolute;
  bottom: 5px;
  right: 10px;
  width: 7px;
  height: 7px;
  border-radius: 100%;
}

.searchResItem .searchResItemInfo div {
  margin-right: 10px;
  flex: 1;
}

.searchResItem .searchResItemInfo {
  flex: 1 1;
  display: flex;
  padding: 10px;
}

.mapViewData {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-top: 5px;

  flex: 1;
  position: relative;
}

#TomTomMap {
  height: 100%;
  background-size: 100% auto;
  display: block;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.mapViewInfo {
  background: #fff;
  border-radius: 20px 20px 0 0;
  width: 320px;
  padding: 20px;
  padding-bottom: 0;
  position: absolute;
  bottom: 0px;
  left: 45px;
  display: flex;
  flex-direction: column;
}

.mapViewInfoClose {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 30px;
  height: 30px;
  background: #eaedf0;
  border-radius: 100%;
  cursor: pointer;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapViewInfoClose img {
  width: 90%;
}

.imgSearchFa {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin: 10px;
  position: relative;
}

.imgSearchFa img {
  width: 100%;
  height: auto;
}
.searchResItemInfo span {
  font-weight: bold;
  margin-right: 10px;
}

.searchResItem {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.driverDrawerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.driverDrawerHeaderImgBox {
  width: 70px;
  height: 70px;
  background: #fff;
  margin-top: -50px;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.driverDrawerHeaderImgBox img {
  width: 100%;
}

.driverDrawerHeaderInfo span {
  color: #44566c;
  font-size: 12px;
  margin-top: 5px;
}

.driverDrawerHeaderInfo strong {
  font-size: 11px;
  font-weight: normal;
}

.driverDrawerHeaderInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.driverDrawerStatus {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #eaedf0;
  padding: 7px 2px;
  margin-bottom: 10px;
}

.driverDrawerStatusText {
  flex: 1;
  background: #f8fafb;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-left: 10px;
}

.driverDrawerStatusText i {
  width: 10px;
  height: 10px;
  background: #09b66d;
  border-radius: 100%;
  margin-right: 5px;
  margin-left: 5px;
}

i.AVAILABLE {
  background: #09b66d;
}

i.DRIVE {
  background: #0081ff;
}

i.WORK {
  background: #fdbf5e;
}

i.REST {
  background: #ff8a48;
}

i.Unknown {
  background: #ff3d57;
}

i.HOLIDAY {
  background: #44566c;
}
.driverDrawerViewFa {
  flex: 1;
  overflow-y: scroll;
  display: flex;
}

.driverDrawerView {
  /*overflow-y: scroll;*/
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.driverDrawerData {
  margin-bottom: 10px;
}

.driverDrawerData .title {
  color: #44566c;
  font-size: 13px;
  font-weight: 900;
}

.driverDrawerDataCol {
  display: flex;
  flex-flow: row wrap;
}

.driverDrawerDataColItem {
  display: flex;
  flex-basis: 50%;
  padding: 8px 0;
  align-items: center;
}

.driverDrawerDataColItemInfo {
  display: flex;
  flex-direction: column;
}

.driverDrawerDataColItemInfo span,
.driverDrawerDataColItemInfo strong {
  font-size: 12px;
}

.driverDrawerDataColItem img {
  width: 30px;
  margin-right: 4px;
  margin-left: 4px;
}

.driverDrawerDataColItem:nth-child(odd) {
  border-right: 1px solid #eaedf0;
  border-bottom: 1px solid #eaedf0;
}

.driverDrawerDataColItem:nth-child(even) {
  border-bottom: 1px solid #eaedf0;
}
.ChartArrow {
  position: absolute;
  /* top: 0; */
  /* left: 0; */
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
}
.ChartArrow img {
  width: 25px;
  height: 25px;
}

.ArrowsChartFa {
  /* background: rgba(0, 0, 0, 0.1); */
  /* border: 3px solid red; */
  position: absolute;
  border-radius: 100%;
  /* width: 95%; */
  height: 105%;
  display: flex;
  justify-content: center;
  aspect-ratio: 1/1;
}

.PieAverage {
  font-size: 24px;
  color: #44566c;
  /* height: 30px !important; */
  position: relative;
  margin-bottom: 20px;
}

.PieAverageValue {
  float: left;
}
.PieAverageValue span {
  font-size: 13px;
}
.PieAverage-number {
  background: #09b66d;
  margin-top: 5px;
  border-radius: 15px;
  padding: 3px 9px;
  font-size: 12px;
  /* height: 23px; */
  color: #fff;
  float: right;
  margin-right: 10px;
}

.PieAverage-iconFa {
  display: flex;
  margin-bottom: 10px;
}

.PieAverage-number-equal {
  background: #ffc570;
}

.PieAverage-number-down {
  background: #b21f2d;
}

.PieAverage .PieAverage-icon {
  width: 20px;
  margin-right: 10px;
  height: 25px;
  margin-top: 0px;
  background-size: 100% auto;
  float: right;
}

.legendRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
}
.CustomLegendDescription {
  color: #8697a8;
}
.legendRow svg {
  transform: rotate(180deg);
}
.legendRow img {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.legendRow span {
  margin-right: 1px;
  margin-left: 5px;
  /* white-space: nowrap; */
  /* width: 100px; */
  margin-right: 35px;
  font-size: 13px;
}
.HorizintlBar {
  width: 95%;
  margin-left: 2.5%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.HorizintlBarArrows {
  position: relative;
  width: 100%;
  height: 25px;
}

.HorizintlBarArrows span {
  position: absolute;
  top: 0;
  margin-left: -10px;
}
.HorizintlBarArrows span:last-child {
  margin-left: -20px;
}
.HorizintlBarBars {
  height: 30px;
  width: 100%;
  border-radius: 8px;
  background: #cfd5dd;
  display: flex;
}
.HorizintlBarBars div {
  height: 100%;
  border-radius: 8px;
}
.HorizintlBarNubmers {
  display: flex;
  width: 100%;
  margin-top: 15px;
  border-top: 4px solid #eaedf0;
  position: relative;
}
.HorizintlBarNubmers span {
  color: #8697a8;
  display: flex;
  position: absolute;
  top: 0;
  flex: 1;
}

.HorizintlBarDesc {
  color: #8697a8;
  margin-top: 30px;
  font-weight: bold;
}
/* .HorizintlBarNubmers span:first-child{
position: absolute;
}
*/
.HorizintlBarNubmers span:last-child {
  position: absolute;
  right: 0;
  margin-left: -20px;
}
.legendRow strong {
  width: 25px;
  font-size: 13px;
  font-weight: 400;
}
.w100 {
  width: 100%;
}
.legBox {
  width: 28px;
  height: 18px;
  margin-right: 3px;
  border-radius: 10px;
}
.CustomLegendFa {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-right: 30px;
  padding-left: 40px;
}
.short_description {
  color: #44566cd2;
  position: absolute;
  left: 18px;
  top: 60px;
  font-size: 15px;
  width: 40%;
}
.CustomLegend {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.chartsContainer {
  height: calc(100% - 70px);
  width: 95%;
  margin-left: 5%;
  /* background: chartreuse; */
  /*overflow: scroll;*/
  overflow: hidden;
  position: relative;
  display: flex;
}
.PieChartContainer {
  height: calc(100% - 100px);
}
.PieChartGrid {
  padding: 20px;
  width: 50%;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
}
.DoughnutPercent {
  position: absolute;
  text-align: center;
  font-family: Roboto;
}

.DoughnutPercent div {
  font-size: 30px;
}

.CCPointContainer {
  height: calc(100% - 40px) !important;
  margin-top: -40px;
  overflow: visible;
  width: 100%;
  position: relative;
  display: flex;
}
.MapLegend {
  position: absolute;
  padding: 10px;
  z-index: 999999;
  bottom: 0;
  border-radius: 0 10px 0 0;
  background: #fff;
  /* width: 260px; */
}
.MapLegend .legendRow {
  justify-content: start !important;
}
.MapLegend .legendRow strong {
  width: auto !important;
}
.itemRow .table td div.tdData {
  display: flex;
  align-items: center;
}

.itemRow .table td div.tdData .header-account-circle {
  margin-right: 10px;
}
.TTMapContainer {
  height: calc(100% - 50px) !important;
  margin-top: -52px;
  overflow: visible;
  width: 100%;
  position: relative;
  display: flex;
}
.Chart-data {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: row;
}
